<template>
  <div>
    <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
      <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  <v-data-table
    :headers="headers"
    :items="org_list"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Manage Organization</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
          ></v-text-field>
          <span style="width: 20px"> </span>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Add Organization</v-btn>
          </template>
          <v-card>
            <v-form v-model="valid">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select 
                    :items="tenant_list"
                      v-model="editedItem.tenantname"
                      item-text="name"
                      item-value="name"
                      persistent-hint
                      :disabled="editedIndex != -1"
                      :filled="editedIndex==-1?false:true"
                      label="*Tenant Name"
                      :rules="[v => !!v || 'Tenant name is required']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.name" label="*Organization Name" dense
                          hint="For example :: VS"
                          :rules="[v => !!v || 'Organization name is required']"
                          ></v-text-field>
                  </v-col>     
                   <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.display_name" label="*Display Name" dense
                          hint="For example :: Vishwakarma School" 
                          :rules="[v => !!v || ' name is required']"
                          ></v-text-field>
                  </v-col> 
                   <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.address" label="Address" dense
                          hint="For example :: Bibwewadi, Pune"
                          ></v-text-field>
                  </v-col>  
                  <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.phone" label="Phone" dense
                          hint="For example :: 976123xxxx"
                          type="number"
                          counter="10"
                          ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.email" label="Email" dense
                          hint="For example :: contact@eduplusnow.com"                            
                          :rules="[v => /.+@.+/.test(v) || 'E-mail must be valid']"
                          ></v-text-field>
                  </v-col> 
                  <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.website" label="Website" dense
                          hint="For example :: https://www.volp.in"
                          ></v-text-field>
                  </v-col> 
                  <v-col cols="12" sm="12" md="12">
                      <v-select	
	                    :items="country_super_master_list"
                      v-model="editedItem.country"
	                    label="Select Country"	
	                    ></v-select>	
                  </v-col>     
                  <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="editedItem.loginattachementdomain" label="*Login Attachment Domain" dense
                          hint="For example :: @eduplusnow.com" 
                           :rules="[required('Domain Name')]"
                          ></v-text-field>
                  </v-col>                                                                   
                   <v-col cols="12" sm="6" md="4" v-if='editedIndex != -1'>
                    <v-checkbox
                        dense
                        v-model="editedItem.isactive"
                        color="success"
                        label="Active"
                      ></v-checkbox>
                  </v-col>     
                </v-row>
               
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="primary darken-1" @click="save" :disabled="!valid">Save</v-btn>              
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

<template v-slot:item.isactive="{ item }">
  <span v-if="item.isactive">
    <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
  </span>
  <span v-else>
    <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
  </span>
</template>

   <template v-slot:item.action="{ item }">
      <div>
       <v-avatar style="" size="25" class="edit-avatar">
      <v-icon
        class="edit-v-icon mr-2" @click="editItem(item)"
      >
        edit
      </v-icon>
       </v-avatar>
      </div>
    </template>
  </v-data-table>
   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>  
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      overlay: false,
      valid:false,
      required(paraname){
        return v => !!v || `You must fill ${paraname}`
      },
      errorMsg:{
        addOrg:false
      },
      snackbar_msg: "",
      color: "",
      snackbar: false,
      search:'',
      dialog: false,
      org_list:[],
      headers: [
        { text: 'Name',align: 'left',value: 'name'},
        { text: 'Display Name',align: 'left',value: 'display_name'},
        { text: 'Tenant Name',align: 'left',value: 'tenantname'},
        { text: 'Address',align: 'left',value: 'address'},
        { text: 'Email',align: 'left',value: 'email'},
        { text: 'Contact',align: 'left',value: 'phone'},
        { text: 'Login attachment domain',align: 'left',value: 'loginattachementdomain'},
        { text: 'Web site',align: 'left',value: 'website'},
        { text: 'Country',align: 'left',value: 'country'},
        { text: 'Active',align: 'left',value: 'isactive'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      tenant_list: null,
      country_super_master_list: null,
      editedIndex: -1,
      editedItem: {
        name:'',
        tenantid:'',
        display_name:'',
        tenantname:'',
        address:'',
        email:'',
        phone:'',
        loginattachementdomain:'',
        website:'',
        country:'',
        isactive:true  
      },
      defaultItem: {
        name:'',
        display_name:'',
        tenantname:'',
        tenantid:'',
        address:'',
        email:'',
        phone:'',
        loginattachementdomain:'',
        website:'',
        country:'',
        isactive:true       
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Organization' : 'Edit Organization'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
        overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 1000)
      },
    },

    mounted(){
         axios
      .post("/Admin/addOrganization")
      .then(res => {
        if (res.data.msg == "200") {
          //window.alert(res.data.msg)
        
          if(this.load = true){
            this.overlay=true;
              this.tenant_list = res.data.tenant_list
              this.country_super_master_list = res.data.country_super_master_list
          this.org_list=res.data.org_list

          }
        } else {
          // window.console.log(res.data.msg)
        }
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        window.console.log(error);
      })
      .finally(()=>{ 
       'use strict';
      var overlay=false;
      //this.overlay= false;
      });
     },
    methods: {
    initializeErrorMsgFalse(){
      this.errorMsg.addOrg=false;
    },
      editItem (item) {
        this.editedIndex = this.org_list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
     showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) 
        {
          if(this.isValidated())
         {
          axios
          .post("/Admin/editOrganization", this.editedItem)
          .then(res => {
            if (res.data.msg == "200") {
              // window.console.log(res.data.msg)
              Object.assign(this.org_list[this.editedIndex], this.editedItem)
            }    
            this.showSnackbar(
                  "#4caf50",
                  "Organization Updated Successfully..."
                ); // show snackbar on success
          })
          .catch(error => {
            window.console.log(error);
          });
            this.close()
         }
        } 
        else 
        {
          if(this.isValidated())
         {
          //this.tenant_list.push(this.editedItem)
           axios
          .post("/Admin/saveOrganization", this.editedItem)
          .then(res => {
            if (res.data.msg == "200") {
              this.org_list.push(this.editedItem)
              this.showSnackbar("#4caf50", "Organization Added Successfully!!!"); // show snackbar on success            
            }
           
          })
          .catch(error => {
            window.console.log(error);
          });
          this.close()
         }
        }
      }, //save()
    isValidated(){
      // alert('I am in isValidated()'+this.editedItem.name +"||" +this.editedItem.usertype.id +"||"+this.editedItem.roletype.id)
        return true
     }, // .....end of isValidated()
    },
  }
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size{
  font-size: 20px;
}
.edit-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover{
  background-color: #b0bec5;
}
.edit-v-icon{
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover{
  color: white;
}
</style>